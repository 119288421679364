import React from 'react'
import styled from 'styled-components';
import logo from '../../logo.png';

const Wrapper = styled.div`
    padding-top: 5vh;
`
const HomePage = () => {
    return (
        <Wrapper className="page">
            <img src={logo} className="App-logo" alt="logo" />
            <p>
                Kwiatki kwiateczki i ich wilgotność
            </p>
        </Wrapper>
    )
}

export default HomePage