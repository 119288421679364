import React from 'react'
import { Routes, Route } from "react-router-dom";
import FlowerChartPage from './pages/charts/FlowerChartPage';
import HomePage from './pages/home/HomePage';
import WhatsUpFlowersPage from './pages/whatsUpFlowers/WhatsUpFlowersPage';

const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/whats-up-flowers" element={<WhatsUpFlowersPage />} />
      <Route path="/charts-flower/:flowerId" element={<FlowerChartPage />} />
    </Routes>
  )
}

export default Routing