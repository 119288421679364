import { List } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { FlowerWithLatestMeasurements } from '../../../models'
import happyFlower from '../../../assets/happy2.png'
import moment from 'moment'

interface IProps {
    flower: FlowerWithLatestMeasurements
    onClick: Function
}

const ListItemWrapper = styled(List.Item)`

    font-size: 2rem!important;
    cursor: pointer;

    h3 {
        margin: 0px;
        margin-right: 5px;
        font-size: 1.2rem;
    }

    .ant-list-item-meta-title { 
        display: flex;
        width: 50%;
        justify-content: center;
    }

    .ant-list-item-meta-content {
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
    }

    .ant-list-item-meta-description {
        display: flex;
        width: 100%;
        justify-content: center;
        font-size: 0.9rem;
    }
`



const ImageStyled = styled.img`
    width: 48px;
    height: auto;

`

const FlowerListItem = ({ flower, onClick }: IProps) => {
    const { measuredAt, value, sensor } = flower.measurements.sort((f1, f2) => f1.measuredAt > f2.measuredAt ? -1 : 1)[0]

    const percentage = (value / sensor.maxValue * 100).toFixed(0)
    const text = `${percentage}% | ${sensor.name}`

    const datetime = moment(measuredAt).format("DD/MM/yyyy HH:mm:ss");

    return (
        <ListItemWrapper
            onClick={() => onClick(flower.id)}
            extra={<ImageStyled src={happyFlower} />}
        >
            <List.Item.Meta
                title={<h3>{flower.name}</h3>}
                description={<div>
                    <div>
                        {text}
                    </div>
                    <div>
                        {datetime}
                    </div>
                </div>
                }
            />
        </ListItemWrapper>
    )
}

export default FlowerListItem