import { Col, List, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { api } from '../../api/agent'
import { FlowerWithLatestMeasurements } from '../../models'
import FlowerListItem from './components/FlowerListItem'
import { useNavigate } from 'react-router-dom';


const PageWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    padding-top: 30px;

`

const ListWrapper = styled(List<FlowerWithLatestMeasurements>)`
    width: 100%;

`

const WhatsUpFlowersPage = () => {
    const [flowers, setFlowers] = useState<FlowerWithLatestMeasurements[]>([])

  const navigate = useNavigate();

    useEffect(() => {
        getFlowers()
    }, [])

    const getFlowers = async () => {
        const response = await api.flowers.getFlowersWithLatestMeasurements();
        setFlowers(response)
    }

    const onFlowerClick = (flowerId: number) => {
        navigate(`/charts-flower/${flowerId}`)
    }

    return (
        <PageWrapper className="page">
            <Row className="w-100" justify='center'>
                <Col xs={24} sm={20} lg={16}>
                    <ListWrapper
                        itemLayout="horizontal"
                        dataSource={flowers}
                        renderItem={flower => <FlowerListItem flower={flower} onClick={onFlowerClick} />}
                    />
                </Col>
            </Row>
        </PageWrapper>
    )
}

export default WhatsUpFlowersPage