import React from 'react';
import logo from './logo.png';
import './App.css';
import 'antd/dist/antd.css';
import MainMenu from './pages/layout/MainMenu';
import Routing from './Routing';

function App() {
  return (
    <div className="App">
      <MainMenu />
      <Routing />
    </div>
  );
}

export default App;
