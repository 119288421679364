import axios from "axios";
import { FlowersApi } from "./endpoints/flowers.api";

export const agent = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})

agent.interceptors.request.use(
    config => {
        if (config.headers) {

            // config.headers['XD-Driven-Token'] = process.env.REACT_APP_XD_TOKEN;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);


export const api = {
    flowers: FlowersApi
}