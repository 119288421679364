import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom';
import { api } from '../../api/agent';
import { AxisOptions, Chart } from 'react-charts'
import moment from "moment";
import "moment/locale/pl";
import { FlowerWithLatestMeasurements } from './../../models/flowerWithLatestMeasurements.d';
import 'chartjs-adapter-moment';
import styled from 'styled-components';
import { Row, Col } from 'antd';

moment.locale("pl");


const Wrapper = styled.div`
`

const ColStyled = styled(Col)`
height: 50vh;

  `

const FlowerChartPage = () => {
  let { flowerId } = useParams();

  const [flower, setFlower] = useState<FlowerWithLatestMeasurements>()

  useEffect(() => {
    getFlower()
  }, [])

  const getFlower = async () => {
    const response = await api.flowers.getFlower(flowerId);
    setFlower(response)
  }

  const data = [
    {
      data: flower?.measurements?.map(x => {
        return {
          id: x.id,
          value: x.value,
          measuredAt: new Date(x.measuredAt)

        }
      })
    },
  ]

  const primaryAxis = useMemo(
    (): AxisOptions<any> => ({
      getValue: datum => datum.measuredAt,
      scaleType: 'time',
      formatters: {

      }
    }),
    []
  )

  const secondaryAxes = useMemo(
    (): AxisOptions<any>[] => [
      {
        getValue: datum => datum.value,
      },
    ],
    []
  )

  if (!flower)
    return <h5>Ładowanie...</h5>


  return (
    <Wrapper>
      <Row justify='center'>
        <ColStyled xs={24} md={20} lg={16} >
          <h2>{`Wilgotność kwiatka ${flower.name}`}</h2>
          <Chart
            options={{
              data: (data as any),
              primaryAxis,
              secondaryAxes,
            }}
          />
        </ColStyled>
      </Row>
    </Wrapper>
  )
}

export default FlowerChartPage