import React from 'react'
import MenuItem from './components/MenuItem';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const Wrapper = styled.div`
  // background-color: #282c34;
  background-color: white;
  padding-top: 30px;
  // padding-left: 30px;
  display: flex;
  justify-content: center;
`

const MainMenu = () => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <MenuItem
        text={" 🏡 "}
        onClick={() => navigate("/")}
      />
      <MenuItem
        text={" 🌼 "}
        onClick={() => navigate("/whats-up-flowers")}
      />
    </Wrapper>
  )
}

export default MainMenu