import React from 'react'
import styled from 'styled-components';

interface IText {
    rotateValue?: number;
}

interface IProps {
    text: string;
    rotation?: number
    onClick: React.MouseEventHandler<HTMLDivElement> | undefined;
}

const Wrapper = styled.div`
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;

    &:hover {
        cursor: pointer;
    }
`

const Text = styled.p<IText>`
    margin-top: 20px;
    font-size: calc(52px + 2vmin);
    background-color: white;
    color: rgba(0,0,0, 0.9);
    padding: 10px;
    border-radius: 20px;
    transform: ${({rotateValue})=> rotateValue ? `rotate(${rotateValue}deg)` : null };
    max-width: 150px;

`

const MenuItem = ({ text, rotation, onClick }: IProps) => {
    return (
        <Wrapper onClick={onClick}>
            <Text rotateValue={rotation}>
                {text}
            </Text>
        </Wrapper>
    )
}

export default MenuItem